'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:infAdminFaq
 * @description
 * # infAdminFaq
 * Directive of the informaApp
 */
angular.module('informaApp')
    .directive('infAdminFaq', ['$cookies', 'FaqSvc', 'ModalHelper', function ($cookies, FaqSvc, ModalHelper) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-admin-faq/template.ptl.html',

            scope: {},

            link: function (scope, element, attrs) {
                scope.newFaq = {};
                scope.newFaqFileOptions = { id: 'Attached' };

                scope.editFaqFileOptions = { id: 'Update' };
                scope.editAttached = null;

                scope.addQuestion = function () {
                    return FaqSvc.addQuestion(createFormData(scope.newFaq))
                        .then(function(res) {
                            scope.getFaqs();
                        })
                        .catch(function(err) { console.log(err) })
                };

                scope.updateQuestion = function (question) {
                    question.attachment = scope.editAttached ? scope.editAttached : '';

                    return FaqSvc.updateQuestion(createFormData(question))
                        .then(function(res) {
                            scope.getFaqs();
                        })
                        .catch(function(err) { console.log(err) })
                };

                scope.deleteQuestion = function (questionId) {
                    return FaqSvc.deleteQuestion(questionId)
                        .then(function(res) {
                            scope.getFaqs();
                        })
                        .catch(function(err) { console.log(err) })
                };

                scope.editQuestion = function (question) {
                    scope.editAttached = null;

                    scope.faqs.forEach(function (q) {
                        q.id === question.id ?
                            question.editable = !question.editable :
                            q.editable = false;
                    });
                };

                scope.removeFile = function (faq) {
                    scope.editAttached = null;
                    faq.isAttachmentRemoved = true;
                };

                scope.getFaqs = function () {
                    FaqSvc.getAllQuestion()
                        .then(function(res) {
                            res.data.forEach(function (q) {
                                q.editable = false;
                                q.updatedAt = moment(q.updatedAt).format('MM/DD/YYYY, h:mm A');
                            });
                            scope.faqs = res.data;
                        })
                        .catch(function(err) { console.log(err) });
                };

                function createFormData (data, filePropName) {
                    var fd = new FormData();

                    for (var key in data) {
                        if(data.hasOwnProperty(key)) {
                            fd.append(key, data[key]);
                        }
                    }

                    return fd;
                }

                ModalHelper.on(ModalHelper.events.hidden, "#faqModal", function () {
                    scope.newFaq.question = "";
                    scope.newFaq.answer = "";
                    scope.newFaq.attachment = null;
                    scope.$digest();
                });

                scope.getFaqs();
            }
        };
    }]);